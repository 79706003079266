.account-balances-page {
  height: 100vh;

  .account-balances-content {
    padding: 18px 0px 80px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: 100%;
    // width: fit-content;
    width: 100%;

    .account-balances {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1.heading {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        display: flex;
        align-items: center;
      }

      .balances-refresh-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;
        border: none;
        outline: none;
        padding: 7px 28px;
        border-radius: 18px;
        background: #e5f4ff;
        color: #0092ff;
        opacity: 1;
        font-size: 13px;
        font-weight: 600;
        span {
          font-size: 1.2rem;
        }
      }
    }
    .account-balances-card thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .account-balances-card {
      margin-top: 20px;
      height: calc(100vh - 300px);
      overflow: auto;
    }
  }
}

// Table Styles
.account-balances-table {
  font-size: 13px;
  // margin-top: 20px;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 4%);

  tr {
    background-color: #fff;
    color: #43425d;
    padding: 4px 0;
    border-color: #bfbfbf;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
  }
}

.account-balances-table {
  font-size: 13px;
  margin-top: 20px;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  .last-child-header {
    text-align: left;
    margin-right: 8px;
  }
  th,
  td {
    text-align: center;
    display: table-cell;
    // padding: 1.8em 4em !important;
    padding: 1.8em 3em !important;
  }
}

// @ to handle last child header styles
// .account-balances-table-header th:last-child {
//   text-align: left;
//   margin-right: 8px;
// }

.no-data__message {
  text-align: center;
  padding: 2rem;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 400;
  }
}

.account-balances-table th {
  border-top: none;
  background: #f5f6fa;
  text-align: center;
  color: #43425d;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}

.account-balances-table tr {
  background-color: #fff;
  color: #43425d;
  padding: 4px 0;
  border-color: #bfbfbf;
  user-select: none;
  cursor: default;
}

tbody tr {
  border-bottom: 3px solid #f5f6fa !important;
  text-align: center;
}

.account-balances-table tr th {
  text-align: center;
}

// .account-balances-table tr th:last-child {
//   text-align: end;
// }

.account-balances-table tr:nth-child(odd) {
  background-color: #fff;
}

.account-balances-table td:first-child {
  margin-top: 0.5em;
}

.account-balances-table td:last-child {
  margin-bottom: 0.5em;
  text-align: end;
}

.account-balances-table tr:hover {
  background-color: #f5f6fa;
}
.account-balances-table td:before {
  display: none;
}

.account-balances-table th:first-child,
.account-balances-table td:first-child {
  padding-left: 0;
}
.account-balances-table th:last-child,
.account-balances-table td:last-child {
  padding-right: 0;
}
