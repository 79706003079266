.account-balances-table {
  // .last-updated-time {
  //   color: #707070;
  //   font-size: 0.7rem;
  //   margin-left: 4px;
  // }

  .last-updated-time {
    color: #707070;
  }

  .balance {
    white-space: nowrap;
  }

  .balance-value {
    font-weight: 500;
    padding-right: 0.5rem;
  }
  .table-loader {
    margin: 0 6rem;
    height: 15px;
    width: 15px;
  }
  .refresh-icon-wrapper {
    background-color: #e5f4ff;
    padding: 8px 12px;
    border-radius: 1rem;
    margin-left: 0.8rem;
    text-align: center;
    vertical-align: center;
    cursor: pointer;
    img {
      width: 8px;
      font-weight: 800;
    }
  }
}
