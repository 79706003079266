.ui-form-details {
  .ui-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ui-form-title {
    color: #43425d;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    font-weight: 600;
  }

  .ui-divider {
    border-top: 1px solid #cbcbcb;
    // margin: 0.2rem 0;
  }

  .ui-back-btn {
    display: flex;
    align-items: center;
    width: 30%;
    cursor: pointer;
    height: 10%;
    gap: 1rem;

    p.ui-heading {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 30px;
      color: #43425d;
      display: flex;
      align-items: center;
    }

    img {
      width: 1.2rem;
    }
  }

  .ui-form-content {
    display: flex;
    flex-flow: column;

    .divider {
      border-top: 1px solid #cbcbcb;
      margin: 2rem 0;
    }

    .ui-form-inputs-section {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      input:disabled {
        cursor: not-allowed; /* Change cursor to not-allowed */
      }

      .MuiAutocomplete-inputRoot {
        border-color: #cccccc;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 7px 15px;
        max-width: 315px;
        margin-left: auto;
        input {
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
        }

        .MuiAutocomplete-tag {
          max-width: 90%;

          .css-6od3lo-MuiChip-label {
            overflow: scroll;
            text-overflow: clip;
          }
          .MuiChip-deleteIcon {
            margin-left: 2px;
          }
        }
      }

      .ui-form-content-input {
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        width: calc(100% / 2 - 50px);
        justify-content: flex-end;
        position: relative;

        .ui-form-element-control {
          justify-content: center;
          gap: 3px;
          margin-left: -16px;
          .css-1wc848c-MuiFormHelperText-root.Mui-error {
            margin-left: 0;
          }

          input {
            width: 100%;
            margin-right: 10px;
          }

          .autocomplete-multi-input-wrapper {
            width: 100%;
          }
          .extra-hint {
            width: 100%;
            text-align: left;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            color: #9f9f9f;
            max-width: 300px;
            margin-right: 14px;
            margin-bottom: 0;
          }
        }

        .ui-form-element-control-default {
          width: 65%;
          justify-content: center;
          gap: 3px;
          margin-left: -16px;
          .css-1wc848c-MuiFormHelperText-root.Mui-error {
            margin-left: 0;
          }

          input {
            width: 100%;
            margin-right: 10px;
          }

          .autocomplete-multi-input-wrapper {
            width: 100%;
          }
          .extra-hint {
            width: 100%;
            text-align: left;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            color: #9f9f9f;
            max-width: 300px;
            margin-right: 14px;
            margin-bottom: 0;
          }
        }

        .css-b62m3t-container,
        .css-3iigni-container {
          position: relative;
          box-sizing: border-box;
          width: 70%;
        }
        .css-3iigni-container > * {
          width: 200px;
        }

        input[type="file"]::file-selector-button {
          cursor: pointer;
          background-color: white;
          margin-right: 1rem;
          margin-left: 1.2rem;
          width: 50%;
          border: 1px solid #cbcbcb;
          padding: 14px;
          transition: border-color 0.2s ease-in-out;
          border-radius: 10px;
        }

        input[type="file"].signed-agreement::file-selector-button {
          cursor: pointer;
          background-color: white;
          margin-right: 1rem;
          margin-left: 2rem;
          width: 50%;
          border: 1px solid #cbcbcb;
          padding: 14px;
          transition: border-color 0.2s ease-in-out;
          border-radius: 10px;
        }

        input[type="file"]::file-selector-button:hover {
          background-color: #f3f4f6;
        }

        input[type="file"]::file-selector-button:active {
          background-color: #e5e7eb;
        }

        input[type="radio"] {
          width: 30%;
          margin-right: 0.2rem;
        }

        .radio-yes-label {
          // border: 1px solid red;
        }
        .radio-no-label {
          // border: 1px solid blue;
          margin-right: 0.2rem;
        }

        input[type="date"] {
          width: 70%;
          border: 1px solid #cbcbcb;
          padding: 15px;
          transition: border-color 0.2s ease-in-out;
          border-radius: 10px;
          cursor: text;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }

        .ui-file-label {
          color: #43425d;
          font-size: 14px;
          text-align: left;
          line-height: 18px;
          font-weight: 600;
          width: 40%;
        }

        .ui-invalid-file-type {
          position: absolute;
          right: -90px;
          height: 18px;
        }

        .ui-label,
        .custom-ui-label {
          color: #43425d;
          font-size: 14px;
          text-align: left;
          line-height: 18px;
          font-weight: 600;
          width: 39%;
          text-wrap: wrap;
        }

        .ui-label-file-field {
          color: #43425d;
          font-size: 14px;
          text-align: left;
          line-height: 18px;
          font-weight: 600;
          width: 8rem;
        }

        .custom-ui-label {
          margin-bottom: auto;
          margin-top: 20px;
          width: 39%;
        }

        .ui-invalid-field-icon {
          position: absolute;
          right: 10px;
          width: 50px;
          background-color: white;
          // outline: 1px solid red;
          height: 80%;
          display: inline !important;
        }

        .ui-invalid-field-icon-helper {
          position: absolute;
          top: 18px;
          right: 15px;
          width: 50px;
          display: inline !important;
        }

        .ui-form-input-box {
          width: 65%;
          border: 1px solid #cbcbcb;
          border-radius: 10px;
          padding: 14px;
          transition: border-color 0.2s ease-in-out;
        }

        .ui-form-input-box.input-error {
          border-color: #de6767;
        }
      }

      .ui-remove-button-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        position: relative;

        img {
          width: 25px;
          height: 10px;
        }

        button {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: center;
          border: 1px solid #f5f5f5;
          border-radius: 100px;
          padding: 15px 7px;
          color: #ffffff;
          font-size: 14px;
          line-height: 5px;
        }
      }
    }

    .ui-add-another-button-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 2rem;

      button {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #f5f5f5;
        border-radius: 100px;
        padding: 12px 25px;
        background-color: #0092ff;
        color: #ffffff;
        font-size: 14px;
        line-height: 13px;
      }
    }

    .website-hint-container {
      width: max-content;
      border: 0.5px solid #0092ff;
      background: #f2f9ff;
      border-radius: 3px;
      padding: 5px 8px;
      margin-top: 0.4rem;
      margin-left: 10.5rem;
      font-size: 0.65rem;
      .website-hint-label {
        font-weight: 600;
        font-size: 0.75rem;
      }
    }

    .ui-button-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 2rem;

      button {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #f5f5f5;
        border-radius: 100px;
        padding: 15px 30px;
        background-color: #f5f5f5;
        color: #d1d1d1;
        font-size: 17px;
        line-height: 17px;
        cursor: not-allowed;
        pointer-events: none;

        &.active {
          background-color: #0092ff;
          color: #ffffff;
          cursor: pointer;
          pointer-events: all;
        }

        span {
          margin-left: 5px;
        }

        .formik-btn-submit {
          margin-left: auto;
          border: 1px solid #f5f5f5;
          border-radius: 50px;
          padding: 12px 24px;
          background-color: #0092ff;
          color: #ffffff;
          font-size: 14px;
        }

        .formik-btn-submit.active {
          color: #ffffff;
          background-color: #0092ff; /* Change background color for active state */
        }

        .formik-btn-submit.disabled {
          opacity: 0.5; /* Reduce opacity when button is disabled */
          cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
        }
      }

      button:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.mt-0 {
  margin-top: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.admin-done-button-container {
  display: flex;
  justify-content: end;
  margin-top: 4rem;
  .formik-btn-submit {
    margin-left: auto;
    border: 1px solid #f5f5f5;
    border-radius: 50px;
    padding: 12px 24px;
    background-color: #0092ff;
    color: #ffffff;
    font-size: 14px;
  }

  .formik-btn-submit.active {
    color: #ffffff;
    background-color: #0092ff; /* Change background color for active state */
  }

  .formik-btn-submit.disabled {
    opacity: 0.5; /* Reduce opacity when button is disabled */
    cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
  }
}
