.payment-channel-form {
  .ui-toggle-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 3rem;
    justify-content: space-between;
    .ui-form-toggle-field {
      width: 100%;
    }
  }
  hr {
    width: 100%;
    margin-top: 40px;
    background-color: #ccc;
  }
}

.navigation-container {
  border: 1px solid #0092ff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  .label-text {
    font-size: 14px;
    font-weight: 500;
  }

  .formik-btn-submit {
    margin-left: auto;
    border: 1px solid #f5f5f5;
    border-radius: 50px;
    padding: 12px 24px;
    background-color: #0092ff;
    color: #ffffff;
    font-size: 14px;
  }

  .formik-btn-submit.active {
    color: #ffffff;
    background-color: #0092ff; /* Change background color for active state */
  }

  .formik-btn-submit.disabled {
    cursor: not-allowed;
    opacity: 0.5; /* Reduce opacity when button is disabled */
    cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
  }

  button:disabled {
    cursor: not-allowed;
  }
}

// button css

.ui-channel-button-container-standalone {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 100px;
    padding: 15px 30px;
    background-color: #f5f5f5;
    color: #d1d1d1;
    font-size: 17px;
    line-height: 17px;

    &.active {
      background-color: #0092ff;
      color: #ffffff;
    }

    span {
      margin-left: 5px;
    }
  }
}
