.ui-form-toggle-field {
  display: flex;
  align-items: center;
  width: calc(100% / 2 - 50px);
  .ui-label {
    color: #43425d;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    font-weight: 600;
    width: 42%;
  }

  .ui-info-icon {
    background: #ffff;
    img {
      background: #ffff;
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
  .ui-switch-wrapper {
    width: 70%;

    .ui-switch {
      position: relative;
      // display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 28px;
      outline: none;

      input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        // Remove the extreme positioning
        // top: -99999px;
        // left: -99999px;
      }

      .ui-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .ui-slider {
        background-color: #2196f3;
      }

      input:focus + .ui-slider {
        box-shadow: 0 0 1px #2196f3;
      }

      input:checked + .ui-slider:before {
        transform: translateX(20px);
      }
    }
  }
}
